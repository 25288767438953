export const imageMappings = {
    52: require('./images/SchoppenAas.png'),
    51: require('./images/SchoppenHeer.png'),
    50: require('./images/SchoppenQueen.png'),
    49: require('./images/SchoppenBoer.png'),
    48: require('./images/Schoppen10.png'),
    47: require('./images/Schoppen9.png'),
    46: require('./images/Schoppen8.png'),
    45: require('./images/Schoppen7.png'),
    44: require('./images/Schoppen6.png'),
    43: require('./images/Schoppen5.png'),
    42: require('./images/Schoppen4.png'),
    41: require('./images/Schoppen3.png'),
    40: require('./images/Schoppen2.png'),
    39: require('./images/KlaverAas.png'),
    38: require('./images/KlaverHeer.png'),
    37: require('./images/KlaverQueen.png'),
    36: require('./images/KlaverBoer.png'),
    35: require('./images/Klaver10.png'),
    34: require('./images/Klaver9.png'),
    33: require('./images/Klaver8.png'),
    32: require('./images/Klaver7.png'),
    31: require('./images/Klaver6.png'),
    30: require('./images/Klaver5.png'),
    29: require('./images/Klaver4.png'),
    28: require('./images/Klaver3.png'),
    27: require('./images/Klaver2.png'),
    26: require('./images/HartenAas.png'),
    25: require('./images/HartenHeer.png'),
    24: require('./images/HartenQueen.png'),
    23: require('./images/HartenBoer.png'),
    22: require('./images/Harten10.png'),
    21: require('./images/Harten9.png'),
    20: require('./images/Harten8.png'),
    19: require('./images/Harten7.png'),
    18: require('./images/Harten6.png'),
    17: require('./images/Harten5.png'),
    16: require('./images/Harten4.png'),
    15: require('./images/Harten3.png'),
    14: require('./images/Harten2.png'),
    13: require('./images/RuitenAas.png'),
    12: require('./images/RuitenHeer.png'),
    11: require('./images/RuitenQueen.png'),
    10: require('./images/RuitenBoer.png'),
    9: require('./images/Ruiten10.png'),
    8: require('./images/Ruiten9.png'),
    7: require('./images/Ruiten8.png'),
    6: require('./images/Ruiten7.png'),
    5: require('./images/Ruiten6.png'),
    4: require('./images/Ruiten5.png'),
    3: require('./images/Ruiten4.png'),
    2: require('./images/Ruiten3.png'),
    1: require('./images/Ruiten2.png'),
};

// 25,12,38,51 