import React, { useRef, useState, useEffect } from 'react';
import DraggableCircle from './DraggableCircle.js';
import './App.css';
import interact from 'interactjs';
import { imageMappings } from './imageMappings.js';
import { imageMappings1 } from './imageMappings1.js';
import Home from './Home.js';
import { collection, getDocs } from "@firebase/firestore";
import { firestore } from "./firebase.js"; // Zorg ervoor dat dit pad correct is naar je firebase configuratie
import infoImage from './images/info.png';


const App = () => {
  const [isGameStarted, setIsGameStarted] = useState(false);
  const dataRef = useRef();
  const [gekozenKaartId, setGekozenKaartId] = useState(null);
  const [circlePositions, setCirclePositions] = useState({});
  const [droppedCards, setDroppedCards] = useState([]);
  const [isCardDropped, setIsCardDropped] = useState(false);
  const [showImageOverlay, setShowImageOverlay] = useState(false);
  const [spelersKaartKeuze, setSpelersKaartKeuze] = useState([]);
  const [actieveSpelerIndex, setActieveSpelerIndex] = useState(0);
  const [spelID, setSpelID] = useState(null); // Voeg dit toe aan je App component state definities
  const [spelers, setSpelers] = useState([]);
  const [shuffledCardOrder, setShuffledCardOrder] = useState([]); // State variabele voor geschudde kaartvolgorde
  const [currentIndex, setCurrentIndex] = useState(0);
  const [cardsInDropzone, setCardsInDropzone] = useState([]);
  const [uniqueDroppedCardIds, setUniqueDroppedCardIds] = useState([]);
  const [countKaartNummer6, setCountKaartNummer6] = useState(0);
  const [aantalCards, setAantalCards] = useState(52); // Initialiseer aantalCards met 52
  const [showPopup, setShowPopup] = useState(false);



  useEffect(() => {
    // Definieer hier de collectie
    const spelersCollection = collection(firestore, `spel/${spelID}/spelers`);

    const getSpelers = async () => {
      try {
        const spelersSnapshot = await getDocs(spelersCollection);
        const spelersList = spelersSnapshot.docs.map(doc => doc.data().naam); // Zorg ervoor dat 'naam' overeenkomt met de veldnaam in je Firestore-documenten
        setSpelers(spelersList);
      } catch (error) {
        console.error("Fout bij het ophalen van spelers:", error);
      }
    };

    getSpelers();
  }, [spelID]);

  // Laat huidige speler en volgende speler zien.
  useEffect(() => {
    const fetchSpelersFromDatabase = async () => {
      const fetchedSpelers = ['Speler 1', 'Speler 2', 'Speler 3'];
      setSpelers(fetchedSpelers);
    };
    fetchSpelersFromDatabase();
  }, []);

  const handleButtonClick = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % spelers.length);
  };


  const handleMove = (id, newPosition) => {
    setCirclePositions((prevPositions) => ({
      ...prevPositions,
      [id]: newPosition,
    }));
  };



  // Voert uit wanneer er een kaart wordt gedropt in de dropzone.
  const handleDrop = (id, key) => {
    if (!isCardDropped) {
      if (id === "21") { // Controleer of de ID gelijk is aan 21
        console.log("if :" + id);
        const imagePath = imageMappings[id];
        if (!cardsInDropzone.some(card => card.id === id)) {
          setCardsInDropzone(prevCards => [...prevCards, { index: droppedCards.length, id }]);
          setUniqueDroppedCardIds(prevIds => [...prevIds, id]); // Voeg de nieuwe ID toe aan de unieke ID's state
        }
        const updatedDroppedCards = [...droppedCards, id];
        setDroppedCards(updatedDroppedCards);
        localStorage.setItem('droppedCards', JSON.stringify(updatedDroppedCards));

        setCardsInDropzone(prevCards => [...prevCards, { index: droppedCards.length, id }]);

        setShowImageOverlay(true);
        setIsCardDropped(true);
        setDroppedCards(prevDroppedCards => [...prevDroppedCards, id]);
        setGekozenKaartId(id);

        const nieuweKaartKeuzes = [...spelersKaartKeuze];
        nieuweKaartKeuzes[actieveSpelerIndex] = { id: id, key: 'gekozenKaartKey' };
        setSpelersKaartKeuze(nieuweKaartKeuzes);
        setCountKaartNummer6((prevCount => prevCount + 1) / 411);
      } else {
        const imagePath = imageMappings[id];
        if (!cardsInDropzone.some(card => card.id === id)) {
          setCardsInDropzone(prevCards => [...prevCards, { index: droppedCards.length, id }]);
          setUniqueDroppedCardIds(prevIds => [...prevIds, id]); // Voeg de nieuwe ID toe aan de unieke ID's state
        }
        const updatedDroppedCards = [...droppedCards, id];
        setDroppedCards(updatedDroppedCards);
        localStorage.setItem('droppedCards', JSON.stringify(updatedDroppedCards));

        setCardsInDropzone(prevCards => [...prevCards, { index: droppedCards.length, id }]);

        setShowImageOverlay(true);
        setIsCardDropped(true);
        setDroppedCards(prevDroppedCards => [...prevDroppedCards, id]);
        setGekozenKaartId(id);

        const nieuweKaartKeuzes = [...spelersKaartKeuze];
        nieuweKaartKeuzes[actieveSpelerIndex] = { id: id, key: 'gekozenKaartKey' };
        setSpelersKaartKeuze(nieuweKaartKeuzes);
      }




    }
  };
  // Laat spelregels zijn bij informatiebutton
  const informationpage = () => {
    setShowPopup(true)
  }
  //informatie/spelregels pagina/popup
  const Popup = ({ onClose }) => {
    return (
      <div className="popup-overlay" onClick={onClose}>
        <div className="popup-content" onClick={(e) => e.stopPropagation()}> {/* Voorkomt dat het klikken op de content de modal sluit */}

          <h2>Spel Regels</h2>`
          <div className='rulepopup'>
            <p>Bij het drankspel kingsen, ook wel kingszen genoemd, is het de bedoeling dat iedereen in de groep omstebeurt een kaart pakt. Elke soort kaart staat voor een bepaalde opdracht. De kaarten liggen in een cirkel rond een shotglas.</p>
            <p>De kaart kies je door uit de cirkel een kaart te pakken en deze daarna naar het vak te slepen.</p>

            <p> <strong>Alle regels op de kaarten:</strong>
              <br></br>
              2 : je mag een slok aan iemand uitdelen
              <br></br>              <br></br>
              3 : De speler moet zelf een slok nemen
              <br></br>              <br></br>
              4 : categorie
              De speler die deze kaart pakt mag een categorie kiezen bijvoorbeeld automerken. Alle spelers moeten na elkaar iets uit deze categorie noemen, wanneer de speler niks meer weet of het lang duurt dan moet diegene een slok nemen
              <br></br>              <br></br>
              5 : plaskaart met deze kaart mag je plassen
              De speler die deze kaart pakt  heeft toestemming om naar de wc te gaan. Deze kaart geldt tot iemand anders deze heeft gepakt
              <br></br>              <br></br>
              6: duimen
              De speler met deze kaart mag tijdens het spel op een willekeurig moment zijn of haar duim op de tafel leggen. De speler die als laatste zijn duim op tafel legt moet een slok nemen.

              Deze kaart mag 1 keer gebruikt worden.              <br></br>              <br></br>
              7: juffen
              Alle spelers gaan samen zover mogelijk proberen te tellen beginnend bij 1. Dus speler 1 :”een” speler 2: “twee” etc.
              Maar bij de tafel van 7 (7,14,21 etc.) en bij het getal 7 (7,17,27) wordt “JUF” gezegd. diegene die als eerste de fout in gaat drinkt een slok.              <br></br>              <br></br>
              8: Kies een regel.
              Deze regel moet telkens uitgevoerd worden voor het nemen van een slok. Doet diegene dit niet dan moet hij nog een slok nemen.
              Deze kaart/regel blijft het hele spel geldig
              Bijv pink omhoog
              <br></br>              <br></br>
              9: drinkmaatje kiezen
              Kies een andere speler als drinkmaatje. Dit wil zeggen dat als jij moet drinken jou drinkmaatje dit ook moet.

              Deze kaart/regel blijft het hele spel geldig
              <br></br>              <br></br>
              10: quiZmaster
              Andere spelers mogen geen vragen meer van je beantwoorden doet diegene dit wel dan moet diegene een slok nemen.
              Deze kaart/regel blijft geldig tot iemand anders deze kaart pakt.              <br></br>              <br></br>
              11 Boer : snake eye
              Andere spelers mogen je niet meer in de ogen aankijken. Doen ze dit wel moeten ze een sloknemen.

              Deze kaart geldt tot iemand anders opnieuw deze kaart pakt.
              <br></br>              <br></br>
              12 Vrouw : uitdelen
              <br></br>              <br></br>
              13 Koning :
              Je mag iets in het glaasje doen
              Er zijn 4 koningen in het spel diegene die de laatste koning pakt moet het glas opdrinken maar mag wel nog iets in het glas erbij doen
              <br></br>              <br></br>
              14 Aas : drink samen met iemand
            </p>              <br></br>
          </div>
          <button onClick={onClose}>Sluiten</button>
        </div>
      </div>
    );
  };
  //Kijkt hoeveel kaarten er in het spel zitten.
  const Cardsingame = () => {
    setAantalCards(prevAantal => prevAantal - 1);

  }

  const Carddelete = (id) => {
    //Afbeelding wordt opnieuw verborgen
    setShowImageOverlay(false);
    console.log(`De kaart met ID ${id} wordt verwijderd.`);
    handleButtonClick();
    Cardsingame();
  };

  //Zorgt ervoor dat de kaarten in een random volgorde liggen.
  const shuffleCardsOnce = () => {
    const shuffledOrder = Array.from({ length: 52 }, (_, i) => i + 1).sort(() => Math.random() - 0.5);
    setShuffledCardOrder(shuffledOrder);
  };

  //Legt alle kaarten in een circel neer
  const renderDraggableCircles = () => {
    const circleComponents = shuffledCardOrder.map((i) => {
      const angle = (i / 52) * 2 * Math.PI;
      const position = circlePositions[i] || { x: 0, y: 0 };

      if (!droppedCards.includes(i)) {
        return (
          <DraggableCircle key={i} id={i} angle={angle} onMove={handleMove} onDrop={() => handleDrop(i)}>
            { }
          </DraggableCircle>

        );
      }


      return null;
    });

    return circleComponents.filter(Boolean);
  };

  useEffect(() => {
    if (uniqueDroppedCardIds.length > 0) {
      console.log("Nieuwe kaarten gedropt:", uniqueDroppedCardIds);
      setUniqueDroppedCardIds([]);
    }
  }, [uniqueDroppedCardIds]);

  useEffect(() => {
    if (isGameStarted) {
      shuffleCardsOnce();
    }
  }, [isGameStarted]);


  // Voeg interactie toe voor de dropzones
  interact('.dropzone').dropzone({
    accept: '.drag-drop',
    overlap: 0.75,
    ondropactivate: (event) => {
      event.target.classList.add('drop-active');
    },
    ondragenter: (event) => {
      const draggableElement = event.relatedTarget;
      const dropzoneElement = event.target;

      dropzoneElement.classList.add('drop-target');
      draggableElement.classList.add('can-drop');

    },
    ondragleave: (event) => {
      event.target.classList.remove('drop-target');
      event.relatedTarget.classList.remove('can-drop');

    },

    ondropmove: (event) => {
      event.target.classList.add('drop-target');
    },
    ondrop: (event) => {
      const draggableElement = event.relatedTarget;
      const id = draggableElement.getAttribute('data-id');

      handleDrop(id);
    },

    ondropdeactivate: (event) => {
      event.target.classList.remove('drop-active');
      event.target.classList.remove('drop-target');
    },
  });

  const dropzoneStyle = {
    display: showImageOverlay ? 'none' : 'block',
  };


  return (
    <div className="App">
      {!isGameStarted ? (

        <Home startGame={(id) => {
          setIsGameStarted(true);
          setSpelID(id);
        }} />
      ) : (
        <>
          <img onClick={informationpage} src={infoImage} className="information"></img>

          <p>
            Aantal kaarten in het spel {aantalCards}
          </p>

          <ul>
            {spelers.length > 0 ? (
              <h1 key={currentIndex}> Speler: {spelers[currentIndex]}</h1>
            ) : (
              <h1>Geen speler gevonden</h1>
            )}
          </ul>

          {showImageOverlay && (
            <div className="image-overlay">
              {/* Achterste kaart, Speelkaart laat hij zien */}
              <img src={imageMappings[droppedCards[droppedCards.length - 1]]} alt="Overlay" onClick={() => Carddelete(droppedCards[droppedCards.length - 1])} />

              <div className="image-overlay1">
                {/* Laat het tekstvak zien over de speelkaart */}
                <img src={imageMappings1[droppedCards[droppedCards.length - 1]]} alt="Overlay" onClick={() => Carddelete(droppedCards[droppedCards.length - 1])} />
              </div>
              <div className="image-overlaybutton">
                {/*Laat de volgende button zien om verder te kunnen nadat een kaart is gepakt*/}
                <button className='buttonimagedelete' onClick={() => Carddelete(droppedCards[droppedCards.length - 1])} >Volgende</button>
              </div>

            </div>
          )}


          {showPopup && <Popup onClose={() => setShowPopup(false)} />}

          <div className='circle-container'>
            {renderDraggableCircles()}



            <div id="outer-dropzone" className="dropzone" style={dropzoneStyle}
              onDrop={() => handleDrop(gekozenKaartId, 'gekozenKaartKey')}
              onDragOver={(e) => e.preventDefault()}>
              sleep je kaart hierheen
            </div>
          </div>
        </>
      )}
    </div>
  );
}

export default App;

