import React, { useEffect, useRef } from 'react';
import interact from 'interactjs';

const DraggableCircle = ({ id, children, onMove, OnDrop, onDropImagePath }) => {
    const cardRef = useRef(null);

    useEffect(() => {
        interact(cardRef.current).draggable({
            listeners: {
                start(event) {
                    // Onthoud de initiële positie bij het starten van het slepen
                    const target = event.target;
                    const rect = target.getBoundingClientRect();

                    onMove(id, { x: event.clientX, y: event.clientY });
                },
                move(event) {
                    const target = event.target;
                    const testx = event.clientX - 185; // Aanpassen aan je behoeften
                    const testy = event.clientY - 305; // Aanpassen aan je behoeften
                    target.style.transform = `translate(${testx}px, ${testy}px)`;
                    onMove(id, { x: testx, y: testy });

                },
            },
            touch: true,
        });
    }, [id, onMove]);

    return (
        <div
            ref={cardRef}
            className="circle-item drag-drop"
            data-id={id}
            style={{ cursor: 'move' }}
        >
            {children}
            {onDropImagePath && (
                <img src={onDropImagePath} alt="Kaart" />
            )}
        </div>
    );
};

export default DraggableCircle;
