export const imageMappings1 = {
    52: require('./images/KaartAas.png'),
    51: require('./images/KaartHeer.png'),
    50: require('./images/KaartVrouw.png'),
    49: require('./images/KaartBoer.png'),
    48: require('./images/Kaart10.png'),
    47: require('./images/Kaart9.png'),
    46: require('./images/Kaart8.png'),
    45: require('./images/Kaart7.png'),
    44: require('./images/Kaart6.png'),
    43: require('./images/Kaart5.png'),
    42: require('./images/Kaart4.png'),
    41: require('./images/Kaart3.png'),
    40: require('./images/Kaart2.png'),
    39: require('./images/KaartAas.png'),
    38: require('./images/KaartHeer.png'),
    37: require('./images/KaartVrouw.png'),
    36: require('./images/KaartBoer.png'),
    35: require('./images/Kaart10.png'),
    34: require('./images/Kaart9.png'),
    33: require('./images/Kaart8.png'),
    32: require('./images/Kaart7.png'),
    31: require('./images/Kaart6.png'),
    30: require('./images/Kaart5.png'),
    29: require('./images/Kaart4.png'),
    28: require('./images/Kaart3.png'),
    27: require('./images/Kaart2.png'),
    26: require('./images/KaartAas.png'),
    25: require('./images/KaartHeer.png'),
    24: require('./images/KaartVrouw.png'),
    23: require('./images/KaartBoer.png'),
    22: require('./images/Kaart10.png'),
    21: require('./images/Kaart9.png'),
    20: require('./images/Kaart8.png'),
    19: require('./images/Kaart7.png'),
    18: require('./images/Kaart6.png'),
    17: require('./images/Kaart5.png'),
    16: require('./images/Kaart4.png'),
    15: require('./images/Kaart3.png'),
    14: require('./images/Kaart2.png'),
    13: require('./images/KaartAas.png'),
    12: require('./images/KaartHeer.png'),
    11: require('./images/KaartVrouw.png'),
    10: require('./images/KaartBoer.png'),
    9: require('./images/Kaart10.png'),
    8: require('./images/Kaart9.png'),
    7: require('./images/Kaart8.png'),
    6: require('./images/Kaart7.png'),
    5: require('./images/Kaart6.png'),
    4: require('./images/Kaart5.png'),
    3: require('./images/Kaart4.png'),
    2: require('./images/Kaart3.png'),
    1: require('./images/Kaart2.png'),
};

// 25,12,38,51 