
import { initializeApp } from 'firebase/app';
import { getFirestore } from '@firebase/firestore';


// Your web app's Firebase configuration
const firebaseConfig = {
    apiKey: "AIzaSyCNcyGwSmWsUsc0kfH6sNPefKy-eyH8Gh8",
    authDomain: "kingsen-6259e.firebaseapp.com",
    projectId: "kingsen-6259e",
    storageBucket: "kingsen-6259e.appspot.com",
    messagingSenderId: "602914215846",
    appId: "1:602914215846:web:ebd4ed985404f579236167"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const firestore = getFirestore(app);

export { firestore };