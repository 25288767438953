import React, { useState } from 'react';
import { collection, addDoc } from '@firebase/firestore';
import { firestore } from './firebase';

function Home({ gekozenKaartId, startGame, spelID: ontvangenSpelID }) {
    const [aantalSpelers, setAantalSpelers] = useState(1);
    const [spelers, setSpelers] = useState([]);
    const [spelersKaartKeuze, setSpelersKaartKeuze] = useState([]);
    const [actieveSpelerIndex, setActieveSpelerIndex] = useState(0);

    const handleAantalSpelersChange = (e) => {
        setAantalSpelers(parseInt(e.target.value));
    };

    const handleSpelerNaamChange = (e, index) => {
        const nieuweSpelers = [...spelers];
        nieuweSpelers[index] = e.target.value;
        setSpelers(nieuweSpelers);
    };
    const startSpel = async () => {


        const spelRef = await addDoc(collection(firestore, 'spel'), {
            status: "actief"
        });

        // Log het spelRef.id naar de console
        console.log("Spel ID:", spelRef.id);

        startGame(spelRef.id);

        await Promise.all(spelers.map(async (spelerNaam, index) => {
            await addDoc(collection(firestore, `spel/${spelRef.id}/spelers`), {
                naam: spelerNaam,
                index: index,
            });
        }));
    };

    return (
        <div>
            <h1>Start een Spel</h1>
            <label>Aantal spelers:</label>
            <input type="number" value={aantalSpelers} onChange={handleAantalSpelersChange} />

            <div>
                {Array.from({ length: aantalSpelers }).map((_, index) => (
                    <div key={index}>
                        <input
                            type="text"
                            placeholder={`Speler ${index + 1} naam`}
                            onChange={(e) => handleSpelerNaamChange(e, index)}
                        />
                    </div>
                ))}
            </div>

            <button onClick={startSpel} >Start Spel</button>
        </div>
    );
}

export default Home;
